var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("About Bussiness")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.about_us,
              expression: "general.about_us",
            },
          ],
          staticClass: "form-control border-dark",
          attrs: { type: "text", cols: "12", rows: "12" },
          domProps: { value: _vm.general.about_us },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.general, "about_us", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Address")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.address,
              expression: "general.address",
            },
          ],
          staticClass: "form-control border-dark",
          attrs: { type: "text", cols: "12", rows: "12" },
          domProps: { value: _vm.general.address },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.general, "address", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Buiness Name")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.business_name,
              expression: "general.business_name",
            },
          ],
          ref: "smtp_port",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.general.business_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.general, "business_name", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Phone Number")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.phone_number,
              expression: "general.phone_number",
            },
          ],
          ref: "smtp_port",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.general.phone_number },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.general, "phone_number", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Business Email")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.email,
              expression: "general.email",
            },
          ],
          ref: "smtp_encription",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.general.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.general, "email", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("New Badge Visibilty Time On Product Carts")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.new_bage_on_product_card_visibility,
              expression: "general.new_bage_on_product_card_visibility",
            },
          ],
          ref: "new_bage_on_product_card_visibility",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.general.new_bage_on_product_card_visibility },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.general,
                "new_bage_on_product_card_visibility",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Minimum Order Total")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.minimum_order_total,
              expression: "general.minimum_order_total",
            },
          ],
          ref: "minimum_order_total",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.general.minimum_order_total },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.general, "minimum_order_total", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Minimum Order Price for Free Shipping")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.general.free_shipping_order_price,
              expression: "general.free_shipping_order_price",
            },
          ],
          ref: "free_shipping_order_price",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.general.free_shipping_order_price },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.general,
                "free_shipping_order_price",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("\n      Submit\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }