<template>
  <div class="form-group row">
    <div class="col-md-6">
      <label>Enable / Disable</label>
      <fieldset class="form-group mb-3">
        <select class="form-control" v-model="wallet.wallet_setting">
          <option value="enable">Enable</option>
          <option value="disable">Disabled</option>
        </select>
      </fieldset>
    </div>

    <div class="col-md-12">
      <br />
      <button @click="updateSetting()" type="submit" class="btn btn-primary">
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import ErrorHandling from "../../../ErrorHandling";
export default {
  data() {
    return {
      wallet: {
        checkin_point: "",
        checkin_hour: "",
        per_point: "",
        per_order_price_point: "",
        redeem_point: "",
        register_points: "",
      },
      errors: new ErrorHandling(),
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },

  methods: {
    fetchSetting() {
      this.$emit("updateLoadingState", true);
      var token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      var responseData = {};

      axios
        .get("/api/admin/setting?type=wallet_setting", config)
        .then((res) => {
          for (var i = 0; i < res.data.data.length; i++) {
            Object.assign(responseData, {
              [res.data.data[i].setting_key]: res.data.data[i].setting_value,
            });
          }
          //console.log('response datass', responseData);
          this.wallet = responseData;
        })
        .finally(() => this.$emit("updateLoadingState", false));
    },

    updateSetting() {
      this.$emit("updateLoadingState", true);
      var wallet = Object.entries(this.wallet);
      var key = [];
      var value = [];

      for (var i = 0; i < wallet.length; i++) {
        key.push(wallet[i][0]);
        value.push(wallet[i][1]);
      }

      //console.log(key, value);

      var token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(
          "/api/admin/setting/wallet_setting",
          {
            _method: "PUT",
            key,
            value,
          },
          config
        )
        .then((res) => {
          if (res.data.status == "Success") {
            this.$toaster.success("Settings has been updated successfully");
          } else if (res.data.status == "Error") {
            this.$toaster.error(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.data.status == "Error") {
            this.$toaster.error(err.response.data.message);
          }
        })
        .finally(() => this.$emit("updateLoadingState", false));
    },
  },
  mounted() {
    this.fetchSetting();
  },
};
</script>
