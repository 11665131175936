var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Medium Height")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.gallary_setting.medium_height,
              expression: "gallary_setting.medium_height",
            },
          ],
          ref: "medium_height",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.gallary_setting.medium_height },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.gallary_setting,
                "medium_height",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Medium Width")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.gallary_setting.medium_width,
              expression: "gallary_setting.medium_width",
            },
          ],
          ref: "medium_width",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.gallary_setting.medium_width },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.gallary_setting, "medium_width", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("\n      Submit\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }