<template>
  <div class="form-group row">
    <div class="col-md-6">
      <label>Google Map Api String</label>
      <fieldset class="form-group mb-3">
        <input
          type="text"
          class="form-control border-dark"
          placeholder=""
          v-model="google.google_map_api_string"
          ref="google_map_api_string"
        />
      </fieldset>
    </div>

    <div class="col-md-12">
      <br />
      <button @click="updateSetting()" type="submit" class="btn btn-primary">
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import ErrorHandling from "../../../ErrorHandling";
export default {
  data() {
    return {
      google: {
        google_map_api_string: "",
      },
      errors: new ErrorHandling(),
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },

  methods: {
    fetchSetting() {
      this.$emit("updateLoadingState", true);
      var token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      var responseData = {};

      axios
        .get("/api/admin/setting?type=business_google_setting", config)
        .then((res) => {
          for (var i = 0; i < res.data.data.length; i++) {
            Object.assign(responseData, {
              [res.data.data[i].setting_key]: res.data.data[i].setting_value,
            });
          }
          //console.log('response datass', responseData);
          this.google = responseData;
        })
        .finally(() => this.$emit("updateLoadingState", false));
    },

    updateSetting() {
      this.$emit("updateLoadingState", true);
      var google = Object.entries(this.google);
      var key = [];
      var value = [];

      for (var i = 0; i < google.length; i++) {
        key.push(google[i][0]);
        value.push(google[i][1]);
      }

      //console.log(key, value);

      var token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(
          "/api/admin/setting/business_google_setting",
          {
            _method: "PUT",
            key,
            value,
          },
          config
        )
        .then((res) => {
          if (res.data.status == "Success") {
            this.$toaster.success("Settings has been updated successfully");
          } else if (res.data.status == "Error") {
            this.$toaster.error(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.data.status == "Error") {
            this.$toaster.error(err.response.data.message);
          }
        })
        .finally(() => this.$emit("updateLoadingState", false));
    },
  },
  mounted() {
    this.fetchSetting();
  },
};
</script>
