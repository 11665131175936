var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Warehouse Id")]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.warehouse_id,
                                    expression: "warehouse_id",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.warehouse_id = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("all"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.warehouses, function (warehouse) {
                                  return _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: warehouse.warehouse_id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(warehouse.warehouse_name) +
                                          "\n                          "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("label", [_vm._v("Product Id")]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.product_id,
                                  expression: "product_id",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.product_id = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("all"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.products, function (product) {
                                return _c(
                                  "option",
                                  { domProps: { value: product.product_id } },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(
                                          product.detail
                                            ? product.detail[0].title
                                            : ""
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              staticStyle: { "margin-top": "20px" },
                              on: {
                                click: function ($event) {
                                  return _vm.purchasReports("")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Filter\n                      "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "table-responsive",
                            attrs: { id: "printableTable" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "sale_wrapper" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "sale_length" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Show\n                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit",
                                            },
                                          ],
                                          attrs: {
                                            name: "sale_length",
                                            "aria-controls": "sale",
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                return _vm.purchasReports()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                            entries"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "dataTables_filter",
                                  attrs: { id: "sale_filter" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: { id: "sale", sale: "grid" },
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { sale: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "53.1891px" },
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Product\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "53.1891px" },
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Purchase Amount " +
                                                _vm._s(_vm.getCurrencyTitle()) +
                                                "\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "53.1891px" },
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Purchase Qty\n                              "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark",
                                      },
                                      _vm._l(
                                        _vm.purchases,
                                        function (purchase, i) {
                                          return _c(
                                            "tr",
                                            {
                                              key: i,
                                              staticClass:
                                                "kt-table-row kt-table-row-level-0 odd",
                                              attrs: { sale: "row" },
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      purchase.title
                                                        ? purchase.title
                                                        : ""
                                                    ) +
                                                    "\n                              "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(purchase.price) +
                                                    "\n                              "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(purchase.qty) +
                                                    "\n                              "
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right",
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.prev_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.purchasReports(
                                                  _vm.pagination.prev_page_url
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                              Previous\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of\n                              " +
                                              _vm._s(_vm.pagination.last_page) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.next_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.purchasReports(
                                                  _vm.pagination.next_page_url
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                              Next\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                      Purchase Report\n                    "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "icons d-flex" }),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }