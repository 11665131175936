<template>
  <div class="card card-custom gutter-b bg-white border-0">
    <div class="card-header border-0 align-items-center">
      <h3 class="card-label mb-0 font-weight-bold text-body">
        Bussiness Setting
      </h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <ul
            class="nav flex-column nav-pills mb-3"
            id="v-pills-tab1"
            role="tablist"
            aria-orientation="vertical"
          >
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('general')"
                :class="{ active: isActive('general') }"
                href="#general"
                >General</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('businesslocation')"
                :class="{ active: isActive('businesslocation') }"
                href="#businesslocation"
                >Business Country</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('chooselanguage')"
                :class="{ active: isActive('chooselanguage') }"
                href="#chooselanguage"
                >Choose Languages</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('choosecurrencies')"
                :class="{ active: isActive('choosecurrencies') }"
                href="#choosecurrencies"
                >Choose Currencies</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('chooseTimezone')"
                :class="{ active: isActive('chooseTimezone') }"
                href="#chooseTimezone"
                >Choose TimeZone</a
              >
            </li>
            <!-- <li class="nav-item">
                        <a class="nav-link" @click.prevent="setActive('pos')" :class="{ active: isActive('pos') }" href="#pos">POS</a>
                    </li> -->
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('emailsmtp')"
                :class="{ active: isActive('emailsmtp') }"
                href="#emailsmtp"
                >Email SMTP Settings</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('sms')"
                :class="{ active: isActive('sms') }"
                href="#sms"
                >SMS</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('notification')"
                :class="{ active: isActive('notification') }"
                href="#notification"
                >Notification</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('invoice')"
                :class="{ active: isActive('invoice') }"
                href="#invoice"
                >INVOICE</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('EmailTemplate')"
                :class="{ active: isActive('EmailTemplate') }"
                href="#EmailTemplate"
              >
                Email Template
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('credentials')"
                :class="{ active: isActive('credentials') }"
                href="#credentials"
              >
                Consumer Keys
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('firebase')"
                :class="{ active: isActive('firebase') }"
                href="#firebase"
              >
                Firebase
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('google')"
                :class="{ active: isActive('google') }"
                href="#google"
              >
                Google
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('points')"
                :class="{ active: isActive('points') }"
                href="#points"
              >
                Points
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                @click.prevent="setActive('wallet')"
                :class="{ active: isActive('wallet') }"
                href="#wallet"
              >
                Wallet
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="tab-content" id="v-pills-tabContent1">
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('general') }"
            >
              <General @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('businesslocation') }"
            >
              <BusinessLocation @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('chooselanguage') }"
            >
              <ChooseLanguage @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('choosecurrencies') }"
            >
              <ChooseCurrencies @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('chooseTimezone') }"
            >
              <TimeZone @updateLoadingState="updateLoadingState" />
            </div>
            <!-- <div class="tab-pane fade" :class="{ 'active show': isActive('pos') }">
                        <Pos @updateLoadingState ="updateLoadingState"/>
                    </div> -->
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('emailsmtp') }"
            >
              <EmailSmtp @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('sms') }"
            >
              <Sms @updateLoadingState="updateLoadingState" />
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('notification') }"
            >
              <Notification @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('invoice') }"
            >
              <Invoice @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('EmailTemplate') }"
            >
              <EmailTemplate @updateLoadingState="updateLoadingState" />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('credentials') }"
            >
              <ConsumerKeys @updateLoadingState="updateLoadingState" />
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('firebase') }"
            >
              <Firebase @updateLoadingState="updateLoadingState" />
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('google') }"
            >
              <Google @updateLoadingState="updateLoadingState" />
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('points') }"
            >
              <points @updateLoadingState="updateLoadingState" />
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'active show': isActive('wallet') }"
            >
              <Wallet @updateLoadingState="updateLoadingState" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileHeader from "./partials/MobileHeader";
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";
import ToolBar from "./partials/ToolBar";
import ColorPanel from "./partials/ColorPanel";
import General from "./settings/General";
import Pos from "./settings/Pos";
import EmailSmtp from "./settings/EmailSmtp";
import Sms from "./settings/Sms";
import Notification from "./settings/Notification";
import EmailTemplate from "./settings/EmailTemplate";
import Invoice from "./settings/Invoice";
import ConsumerKeys from "./settings/ConsumerKeys";
import Firebase from "./settings/Firebase.vue";
import Google from "./settings/Google.vue";
import Points from "./settings/Points.vue";
import Wallet from "./settings/Wallet.vue";
import BusinessLocation from "./settings/BusinessLocation.vue";
import ChooseLanguage from "./settings/ChooseLanguage.vue";
import ChooseCurrencies from "./settings/ChooseCurrencies.vue";
import TimeZone from "./settings/TimeZone.vue";

export default {
  name: "Dashboard",
  components: {
    MobileHeader,
    ToolBar,
    ColorPanel,
    Sidebar,
    Header,
    Footer,
    General,
    Pos,
    EmailSmtp,
    Sms,
    Notification,
    EmailTemplate,
    Invoice,
    ConsumerKeys,
    Firebase,
    Google,
    Points,
    Wallet,
    BusinessLocation,
    ChooseLanguage,
    ChooseCurrencies,
    TimeZone,
  },
  data() {
    return {
      activeItem: "general",
    };
  },

  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    updateLoadingState(state) {
      //console.log(state,"working")
      this.$parent.loading = state;
    },
  },
};
</script>
