var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SEO Title")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.seo_title,
              expression: "webgeneral.seo_title",
            },
          ],
          ref: "seo_title",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.seo_title },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "seo_title", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SEO Meta-Tag")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.seo_meta_tags,
              expression: "webgeneral.seo_meta_tags",
            },
          ],
          ref: "seo_meta_tags",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.seo_meta_tags },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "seo_meta_tags", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SEO Keyword")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.seo_keywords,
              expression: "webgeneral.seo_keywords",
            },
          ],
          ref: "seo_keywords",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.seo_keywords },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "seo_keywords", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SEO Description")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.seo_description,
              expression: "webgeneral.seo_description",
            },
          ],
          ref: "seo_description",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.seo_description },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "seo_description", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("\n      Submit\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }